<template>
  <div>
    <!-- <el-scrollbar wrap-style="max-height: 65vh;" style="padding: 10px;"> -->
      <el-form label-position="right" class="mb-2" style="width: 100%;overflow-x: hidden;">
        <el-row>
          <el-col :span="24">
            <question-type :title="'Question Type'" :field="field" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <question-text :title="'Question Text'" :field="field" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <question-description :title="'Description'" :field="field" />
          </el-col>
          <el-col :span="6">
            <question-number :field="field" />
          </el-col>
        </el-row>
        <el-row v-if="field.question_type == 'MCQ'">
          <el-col :span="24">
            <question-options :title="'Question Options'" :field="field" :standardQuestions="standardQuestions" />
          </el-col>
        </el-row>

        <el-row type="flex" :gutter="30">
          <el-col :span="8" class="mt-1">
            <field-filled-by :field="field" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <is-field-required :field="field" />
          </el-col>
          <el-col :span="10">
        <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
          </el-col>
        </el-row>
      </el-form>
    <!-- </el-scrollbar> -->
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";

export default {
  name: "Question",
  components: {
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    QuestionType: () => import("./QuestionType"),
    QuestionText: () => import("./QuestionText"),
    QuestionDescription: () => import("./QuestionDescription"),
    QuestionNumber: () => import("./QuestionNumber"),
    QuestionOptions: () => import("./QuestionOptions"),
  },
  props: ["field","standardQuestions"],
  data() {
    return {
      iconPickerdialogVisible: false,
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1",
        },
        {
          title: "Folder 2",
          _id: "2",
        },
        {
          title: "Folder 3",
          _id: "3",
        },
      ],
    };
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
  }
};
</script>

<style lang="scss"></style>
